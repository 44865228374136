<template>
    <div v-show="visible" id="details-container" class="card flex-1 ml-4 sticky top-4 overflow-y-auto self-start card-f-h-local-details">
        <ModalEditInvoice
            v-if="modalEditInvoiceVisible"
            :modalEditInvoice="modalEditInvoice"
            :selectedInvoiceProp="invoiceData"
            @refresh="getDetails"
            @close="modalEditInvoice = false"
            @closed="modalEditInvoiceVisible = false"
        />
        <div v-if="visibleComputed" id="content">
            <Menu
                :invoiceUuid="invoiceData.uuid"
                :invoiceStatus="invoiceData.status"
                @editInvoice="showModalEditInvoice"
                @refresh="refreshDetailsAndList"
                @deleted="closeDetailsAndRefreshList"
            />

            <div v-loading="$wait.is('loading.details')" element-loading-spinner="el-custom-spinner">
                <UserClientInformation
                    :invoiceData="invoiceData"
                />
                <InvoiceContent
                    :invoiceData="invoiceData"
                    @refresh="refreshDetailsAndList"
                />
                <Footer
                    :invoiceData="invoiceData"
                />
            </div>
        </div>
    </div>
</template>
<script>
export default {
    components: {
        Menu:                  () => import(/* webpackChunkName: "InvoiceDetails/Menu" */ './details/Menu'),
        UserClientInformation: () => import(/* webpackChunkName: "InvoiceDetails/UserClientInformation" */ './details/UserClientInformation'),
        InvoiceContent:        () => import(/* webpackChunkName: "InvoiceDetails/InvoiceContent" */ './details/InvoiceContent'),
        Footer:                () => import(/* webpackChunkName: "InvoiceDetails/Footer" */ './details/Footer'),
        ModalEditInvoice:      () => import(/* webpackChunkName: "InvoiceDetails/ModalEditInvoice" */ '../modals/ModalEditInvoice'),
    },

    props: {
        visible: {
            type:    Boolean,
            default: false,
        },
        invoiceUuid: {
            type:    String,
            default: null,
        },
    },

    data() {
        return {
            modalEditInvoice:        false,
            modalEditInvoiceVisible: false,
            visibleComputed:         false,
            clientData:              {},
            invoiceData:             {
                user_data: {
                    company_name: null,
                    street:       null,
                    post_code:    null,
                    city:         null,
                    country:      null,
                },
                client_data: {
                    custom_id:         null,
                    name:              null,
                    surname:           null,
                    type:              null,
                    person_org_nummer: null,
                    street:            null,
                    post_code:         null,
                    city:              null,
                    country:           null,
                },
            },
        };
    },

    watch: {
        visible() {
            if (this.visible) {
                this.visibleComputed = true;
                return;
            }
            this.visibleComputed = false;
        },
        invoiceUuid() {
            if (!this.invoiceUuid) return;
            this.getDetails();
        },
    },

    methods: {
        async getDetails() {
            this.$wait.start('loading.details');
            try {
                const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/economy/invoices/${this.invoiceUuid}`);
                this.invoiceData = data;
                this.clientData = data.client_data;
                this.userData = data.user_data;
                this.$wait.end('loading.details');
            } catch (error) {
                this.$wait.end('loading.details');
            }
        },

        refreshDetailsAndList() {
            this.$emit('refresh');
            this.getDetails();
        },

        closeDetailsAndRefreshList() {
            this.$emit('refresh');
            this.$store.commit('economy/toggleDetailsView', false);
        },

        async showModalEditInvoice() {
            this.modalEditInvoiceVisible = true;
            await new Promise(resolve => setTimeout(resolve, 50));
            this.modalEditInvoice = true;
        },
    },
};
</script>
<style>
.card-f-h-local-details {
    max-height: calc(100vh - 30px);
}
</style>
